import React from 'react'
import { ControlFeedback } from '@smooth-ui/core-sc'
import { Field } from 'react-final-form'

const FieldError = ({ name }) => (
  <Field name={name} subscription={{ Formerror: true, touched: true }}>
    {({ meta: { touched, error } }) =>
      touched && error ? <ControlFeedback valid={!error} /> : null
    }
  </Field>
)

export default FieldError
