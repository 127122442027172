import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { FormGroup, Button, Label, Input, Textarea, Select } from '@smooth-ui/core-sc'
import { Form, Field } from 'react-final-form'
import Helmet from 'react-helmet'

import Hero from 'components/hero'
import Layout from 'components/layout'
import TextContainer from 'components/common/text-container'
import { Heading } from 'components/common/typography'
import { gray } from 'components/theme/colours'
import { primaryBoldWeight } from 'components/theme/typography'
import adapt from 'connectors/adapt'
import FieldError from 'components/common/field-error'
import { required } from 'utils/form-validators'

const StyledHref = styled.a`
  text-decoration: none;
  color: ${gray};
  font-weight: ${primaryBoldWeight};
`

const StyledLabel = styled(Label)`
  font-weight: ${primaryBoldWeight};
`

const AdaptedInput = adapt(Input)
const AdaptedTextarea = adapt(Textarea)
const AdaptedSelect = adapt(Select)

class ContactPage extends React.Component {
  state = { submitted: false }

  handleSubmit = async data => {
    await fetch('https://hooks.zapier.com/hooks/catch/3012319/3xwm7er/', {
      method: 'POST',
      body: JSON.stringify(data),
    })

    this.setState({ submitted: true })
  }

  render() {
    const { data } = this.props
    const { submitted } = this.state

    return (
      <Layout>
        <Hero file={data.heroImage} />
        <Helmet
          title='Get a Quote | Pinya Letters'
          meta={[
            {
              name: 'description',
              content:
                'Get a quote within 24 hours for your wedding or other event using a quick form.',
            },
          ]}
        />
        <TextContainer>
          <Heading>Get a Quote</Heading>
          <p>
            Get a quote within 24 hours for your wedding or other event. Simply use the form below,
            or send me an email directly to{' '}
            <StyledHref href='mailto:hello@pinyaletters.com'>hello@pinyaletters.com</StyledHref>.
          </p>
          {submitted ? (
            <p>
              <strong>Thanks! I will be in touch shortly.</strong>
            </p>
          ) : (
            <Form
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, pristine, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <StyledLabel htmlFor='name'>Name</StyledLabel>
                    <Field
                      id='name'
                      name='name'
                      component={AdaptedInput}
                      placeholder='Jane'
                      validate={required}
                      control
                    />
                    <FieldError name='name' />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel htmlFor='email'>Email</StyledLabel>
                    <Field
                      id='email'
                      name='email'
                      component={AdaptedInput}
                      placeholder='jane@gmail.com'
                      validate={required}
                      control
                    />
                    <FieldError name='email' />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel htmlFor='phoneNumber'>Phone Number</StyledLabel>
                    <Field
                      id='phoneNumber'
                      name='phoneNumber'
                      component={AdaptedInput}
                      placeholder='416 555 5555'
                      control
                    />
                    <FieldError name='phoneNumber' />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel htmlFor='message'>Message</StyledLabel>
                    <Field
                      id='message'
                      name='message'
                      component={AdaptedTextarea}
                      placeholder='Quote for a wedding seating chart on a mirror surface for 100 guests…'
                      validate={required}
                      control
                    />
                    <FieldError name='message' />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel htmlFor='foundOn'>Where did you hear about me?</StyledLabel>
                    <Field
                      id='foundOn'
                      name='foundOn'
                      component={AdaptedSelect}
                      validate={required}
                      control
                    >
                      <option value=''>(select)</option>
                      <option value='instagram'>Instagram</option>
                      <option value='google'>Google</option>
                      <option value='facebook'>Facebook</option>
                      <option value='eventbrite'>Eventbrite</option>
                      <option value='friend'>Friend</option>
                      <option value='event'>Event</option>
                      <option value='other'>Other</option>
                    </Field>
                    <FieldError name='foundOn' />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel htmlFor='referralCode'>Referral Code</StyledLabel>
                    <Field id='referralCode' name='referralCode' component={AdaptedInput} control />
                    <FieldError name='referralCode' />
                  </FormGroup>
                  <Button variant='dark' type='submit' disabled={pristine || invalid}>
                    Send
                  </Button>
                </form>
              )}
            />
          )}
        </TextContainer>
      </Layout>
    )
  }
}

export const query = graphql`
  query HeroPageQuery {
    heroImage: file(name: { eq: "hero10" }) {
      ...HeroFile
    }
  }
`

export default ContactPage
